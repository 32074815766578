<template>
  <div class="home">
    <!--  图标链接  -->
    <link
      rel="stylesheet"
      href="https://use.fontawesome.com/releases/v5.2.0/css/all.css"
      integrity="sha384-hWVjflwFxL6sNzntih27bfxkr27PmbbK/iSvJ+a4+0owXq79v+lsFkW54bOGbiDQ"
      crossorigin="anonymous"
    />
    <el-header style="height: 60px">
      <home-topbar :is-home="true" :is-personal="false" @child="getheight">
      </home-topbar>
    </el-header>
    <el-container>
      <el-aside ref="aside" class="left-menu" style="width: auto">
        <el-menu
          ref="menu"
          class="el-menu-vertical-demo"
          text-color="#f0f2f5"
          :default-active="activeIndex"
          active-text-color="#f0f2f5"
          :collapse="isCollapse"
          @open="toMenuPage"
          @select="handleSelect"
        >
          <!-- <div class="slide_style" ref="slide"></div> -->
          <div ref="first_item">
            <el-menu-item :index="`/home/my_file`">
              <!--              <svg-icon icon-class="my_file"></svg-icon>-->
              <i class="iconfont icon-my_file"></i>
              <span slot="title">{{ $t("menu.file") }}</span>
            </el-menu-item>
          </div>
          <el-menu-item :index="`/home/node`">
            <i class="iconfont icon-jiedian"></i>
            <span slot="title">{{ $t("menu.node") }}</span>
          </el-menu-item>

          <el-submenu index="1">
            <template slot="title">
              <div>
                <i class="iconfont icon-fabu_w"></i>
                <span slot="title">{{ $t("menu.release") }}</span>
              </div>
            </template>
            <el-menu-item :index="`/home/release/baseline`">
              <i class="iconfont icon-jixian"></i>
              <span slot="title">{{ $t("menu.baseline") }}</span>
            </el-menu-item>
            <el-menu-item :index="`/home/release/version`">
              <i class="iconfont icon-banben"></i>
              <span slot="title">{{ $t("menu.version") }}</span>
            </el-menu-item>
            <el-menu-item :index="`/home/release/test`">
              <i class="iconfont icon-jiance"></i>
              <span slot="title">{{ $t("menu.test") }}</span>
            </el-menu-item>
          </el-submenu>

          <el-menu-item :index="`/home/my_collaboration`" class="pingshenmenu">
            <i class="iconfont icon-my_colla"></i>
            <span slot="title"
              >{{ $t("addMyColla.review") }}
              <div v-show="unHandleTask > 0 && !isCollapse" class="number">
                {{ unHandleTask > 99 ? "99+" : unHandleTask }}
              </div>
            </span>
          </el-menu-item>
          <el-menu-item :index="`/home/my_change_request`" class="pingshenmenu">
            <i class="iconfont icon-biangeng_w"></i>
            <span slot="title"
              >{{ $t("menu.changeRequest") }}
              <div v-show="unHandleRequest > 0 && !isCollapse" class="number">
                {{ unHandleRequest > 99 ? "99+" : unHandleRequest }}
              </div>
            </span>
          </el-menu-item>
          <el-submenu
            v-show="showDFMEA || showtara || showhara"
            index="4"
            class="safe"
          >
            <template slot="title">
              <div>
                <i class="iconfont icon-gongnenganquan"></i>
                <span slot="title"
                  >{{ $t("menu.FunctionalSafety") }}

                  <span
                    v-show="unHandleDFMEA > 0 || unHandleTARA > 0"
                    class="safe_number"
                  ></span>
                </span>
              </div>
            </template>
            <el-menu-item
              v-show="showDFMEA"
              :index="`/home/functionalSafety/dfmea`"
              class="dfmeamenu"
            >
              <i class="iconfont icon-dfmea"></i>
              <span slot="title"
                >{{ $t("menu.DFMEA") }}
                <div v-show="unHandleDFMEA > 0" class="number">
                  {{ unHandleDFMEA > 99 ? "99+" : unHandleDFMEA }}
                </div>
              </span>
            </el-menu-item>
            <el-menu-item
              v-show="showhara"
              :index="`/home/functionalSafety/hara`"
              class="taramenu"
            >
              <i class="iconfont icon-hara"></i>
              <span slot="title">HARA </span>
            </el-menu-item>
            <el-menu-item
              v-show="showtara"
              :index="`/home/functionalSafety/tara`"
              class="taramenu"
            >
              <i class="iconfont icon-tara"></i>
              <span slot="title"
                >TARA
                <div
                  v-show="unHandleTARA > 0"
                  class="number"
                  style="color: white"
                >
                  {{ unHandleTARA > 99 ? "99+" : unHandleTARA }}
                </div>
              </span>
            </el-menu-item>
          </el-submenu>
          <el-menu-item :index="`/home/board`">
            <i class="iconfont icon-minjiekanban"></i>
            <span slot="title">{{ $t("menu.board") }}</span>
          </el-menu-item>

          <el-menu-item :index="`/home/gantt`">
            <i class="iconfont icon-a-gantt2"></i>
            <span slot="title">{{ $t("gantt.info1") }}</span>
          </el-menu-item>
          <el-menu-item
            v-show="
              jobAuthorities &&
              jobAuthorities.indexOf('KNOWLEDGE_ACCESS') !== -1
            "
            :index="`/knowledge`"
          >
            <i class="iconfont icon-knowledge"></i>
            <span slot="title">{{ $t("certificate.KnowledgeBase") }}</span>
          </el-menu-item>
          <el-menu-item :index="`/home/chart`">
            <i class="iconfont icon-shujutongji"></i>
            <span slot="title">{{ $t("menu.chart") }}</span>
          </el-menu-item>
          <el-submenu index="5">
            <template slot="title">
              <div>
                <i class="iconfont icon-a-huaban12"></i>
                <span slot="title">{{ $t("menu.shortcuts") }}</span>
              </div>
            </template>
            <el-menu-item
              v-for="(item, index) in shortcutsList"
              :key="item.url"
            >
              <a
                :href="item.url"
                target="_blank"
                style="
                  display: inline-block;
                  width: 100%;
                  color: rgb(240, 242, 245);
                "
              >
                <i class="iconfont icon-a-huaban6"></i>
                <span
                  slot="title"
                  style="
                    display: inline-block;
                    width: 60%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  "
                  >{{ item.name }}
                </span>
                <i
                  v-show="role === 'MANAGER'"
                  class="iconfont icon-a-huaban5"
                  style="float: right"
                  @click.stop.prevent="deleteShortcuts(item, index)"
                ></i>
              </a>
            </el-menu-item>
            <el-menu-item v-show="role === 'MANAGER'" @click="addShortcuts">
              <i class="iconfont icon-a-huaban3"></i>
              <span slot="title"></span>
            </el-menu-item>
          </el-submenu>
          <el-menu-item v-show="role === 'MANAGER'" :index="`/home/bin`">
            <i class="iconfont icon-huishouzhan"></i>
            <span slot="title">{{ $t("menu.bin") }}</span>
          </el-menu-item>
          <el-submenu v-show="role === 'MANAGER'" index="2">
            <template slot="title">
              <div>
                <i class="iconfont icon-a-setting1"></i>
                <span slot="title">{{ $t("menu.setting") }}</span>
              </div>
            </template>
            <el-menu-item :index="`/home/setting/user`">
              <i class="iconfont icon-user1"></i>
              <span slot="title">{{ $t("menu.user") }}</span>
            </el-menu-item>
            <el-menu-item :index="`/home/permission`">
              <i class="iconfont icon-permission"></i>
              <span slot="title">{{ $t("menu.permission") }}</span>
            </el-menu-item>
            <el-submenu index="3">
              <template slot="title">
                <div>
                  <i class="iconfont icon-wenjian"></i>
                  <span slot="title">{{ $t("menu.settingFile") }}</span>
                </div>
              </template>
              <el-menu-item :index="`/home/setting/file_type/work_flow`">
                <span slot="title">{{ $t("menu.fileType") }}</span>
              </el-menu-item>
              <el-menu-item :index="`/home/setting/file_type/field_lab`">
                <span slot="title">{{ $t("menu.fieldLab") }}</span>
              </el-menu-item>
              <el-menu-item :index="`/home/setting/file_type/pingshen`">
                <span slot="title">{{ $t("addMyColla.review") }}</span>
              </el-menu-item>
            </el-submenu>
            <el-menu-item :index="`/home/change_request`">
              <i class="iconfont icon-biangengqingqiu"></i>
              <span slot="title">{{ $t("menu.changeRequest") }}</span>
            </el-menu-item>
            <el-menu-item :index="`/home/project_details`">
              <i class="iconfont icon-xiangmupeizhi"></i>
              <span slot="title">{{ $t("menu.projectDetails") }}</span>
            </el-menu-item>
          </el-submenu>
        </el-menu>
        <el-menu
          text-color="#f0f2f5"
          class="test-collapse"
          :collapse="isCollapse"
        >
          <el-menu-item
            style="display: flex; align-items: center"
            index="1"
            @click="collpaseAside"
          >
            <i
              v-if="!isCollapse"
              class="iconfont icon-shouqi1"
              style="font-size: 20px; margin-right: 12px; color: #fff"
            ></i>
            <i
              v-else
              class="iconfont icon-zhedie1"
              style="font-size: 20px; color: #fff"
            ></i>
            <span v-show="isCollapse" slot="title">{{
              $t("menu.unfold")
            }}</span>
          </el-menu-item>
        </el-menu>
      </el-aside>
      <el-main style="padding-left: 35px">
        <router-view :user-list="userList"></router-view>
      </el-main>
    </el-container>
    <AIdialog></AIdialog>
    <el-dialog
      :visible.sync="addShortcutsdialog"
      width="25%"
      :title="$t('menu.addShortcut')"
      :close-on-click-modal="false"
      :show-close="true"
    >
      <el-form
        ref="shortcutsForm"
        label-width="80px"
        label-position="left"
        :model="shortcutsForm"
        :rules="shortcutsRules"
      >
        <el-form-item :label="$t('menu.link')" prop="url">
          <el-input v-model="shortcutsForm.url"></el-input>
        </el-form-item>
        <el-form-item :label="$t('menu.name')" prop="name">
          <el-input v-model="shortcutsForm.name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="info"
          size="small"
          @click="addShortcutsdialog = false"
          >{{ $t("btn.cancelBtn") }}</el-button
        >
        <el-button type="primary" size="small" @click="saveShortcuts">{{
          $t("btn.newBtn")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import vmson from "@/utils/vmson";
import HomeTopbar from "@/components/homeTopbar";
import AIdialog from "@/components/AIdialog";
import { get_tenant_user_list } from "@/network/user/index.js";
import { getUnhandleTask } from "../network/collaboration/index.js";
import { getCount } from "@/network/changeRequests/index.js";
import { get_allRiskAnalysisPage } from "@/network/tara/index.js";
import { notComplete } from "@/network/safety/index";
import { get_component_license } from "@/network/license";
import { getShortcuts, addShortcuts, deleteShortcuts } from "@/network/home";
import { mapGetters } from "vuex";
//临时代码
import config from "@/../config/index";

export default {
  name: "Home",
  components: { HomeTopbar, AIdialog },
  data() {
    return {
      noActive: "2",
      isCollapse: false,
      dynamicTags: [],
      inputVisible: false,
      projectId: [],
      inputValue: "",
      value: null,
      userList: [],
      imageUrl:
        "https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png",
      nickUrl: [],
      top: 1,
      num: 1,
      height: 1,
      activeIndex: "",
      haveUnread: false,
      mindMapId: null,
      form: {
        //新建文件列表
        name: "",
        region: "",
      },
      form_name: "",
      form_options: [],
      fileTypeId: "",
      role: "",
      unHandleTask: 0,
      unHandleDFMEA: 0,
      unHandleTARA: 0,
      unHandleRequest: 0,
      licenseList: [],
      shortcutsList: [],
      addShortcutsdialog: false,
      shortcutsForm: {
        url: "",
        name: "",
      },
      shortcutsRules: {
        name: [
          {
            required: true,
            message: this.$t("projectDetails.message.warn2"),
            trigger: "blur",
          },
        ],
        url: [
          {
            required: true,
            message: this.$t("projectDetails.message.warn2"),
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters([
      "jobAuthorities",
      "showDfmea",
      "showTARA",
      "showHARA",
      "userAccountId",
    ]),
    geturl() {
      return this.$route.params.projectId;
    },
    showtara() {
      if (this.licenseList.indexOf("TARA") !== -1 || this.showTARA) {
        return true;
      } else {
        return false;
      }
    },
    showhara() {
      if (this.licenseList.indexOf("HARA") !== -1 || this.showHARA) {
        return true;
      } else {
        return false;
      }
    },
    showDFMEA() {
      if (this.licenseList.indexOf("DFMEA") !== -1 || this.showDfmea) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    "$store.state.project": {
      handler: function () {
        this.role =
          this.$store.getters.projectRole ||
          sessionStorage.getItem("projectRole");
        if (this.get_pid()) {
          getUnhandleTask(this.get_pid()).then((res) => {
            this.unHandleTask = res;
          });
          let params = {
            page: 0,
            size: 9999,
            assignee: this.userAccountId,
            status: "TO_DO",
          };
          get_allRiskAnalysisPage(this.get_pid(), params).then((res) => {
            this.unHandleTARA = res.totalElements;
          });
          notComplete({
            projectId: this.get_pid(),
          }).then((res) => {
            this.unHandleDFMEA = res;
          });
          getCount({
            projectId: this.get_pid(),
          }).then((res) => {
            this.unHandleRequest = res;
          });
        }
      },
      deep: true,
    },
    $route: {
      handler: function () {
        //监听路由，实时转换选中的tab
        if (this.$route.path.includes("/home/chart")) {
          this.activeIndex = "/home/chart";
        } else if (this.$route.path.includes("/home/release")) {
          this.activeIndex =
            "/home/release/" + this.$route.path.split("/").slice(-2, -1)[0];
        } else if (this.$route.path.includes("/home/board")) {
          this.activeIndex = "/home/board";
        } else if (this.$route.path.includes("/home/gantt")) {
          this.activeIndex = "/home/gantt";
        } else if (this.$route.path.includes("/home/functionalSafety/dfmea")) {
          this.activeIndex = "/home/functionalSafety/dfmea";
        } else if (this.$route.path.includes("/home/functionalSafety/tara")) {
          this.activeIndex = "/home/functionalSafety/tara";
        } else if (this.$route.path.includes("/home/functionalSafety/hara")) {
          this.activeIndex = "/home/functionalSafety/hara";
        } else if (this.$route.path.includes("/home/bin")) {
          this.activeIndex = "/home/bin";
        } else if (this.$route.path.includes("/home/permission")) {
          this.activeIndex = "/home/permission";
        } else if (
          this.$route.path.includes("/home/setting/file_type/work_flow")
        ) {
          this.activeIndex = "/home/setting/file_type/work_flow";
        } else if (
          this.$route.path.includes("/home/setting/file_type/field_lab")
        ) {
          this.activeIndex = "/home/setting/file_type/field_lab";
        } else if (
          this.$route.path.includes("/home/setting/file_type/pingshen")
        ) {
          this.activeIndex = "/home/setting/file_type/pingshen";
        } else if (this.$route.path.includes("/home/change_request")) {
          this.activeIndex = "/home/change_request";
        } else if (
          this.$route.path.includes("/home/setting/file_type/template_set")
        ) {
          this.activeIndex = "/home/setting/file_type/work_flow";
        } else {
          this.activeIndex = this.$route.path.replace(
            "/" + this.$route.params.projectId,
            ""
          );
        }
        this.getShortcuts();
      },
      immediate: true,
    },
    isCollapse(newValue) {
      this.$store.commit("SET_COLLAPSE", newValue);
    },
  },
  mounted() {
    let this_ = this;
    vmson.$on("close_Collapse", (val) => {
      this_.isCollapse = true;
    });
    vmson.$on("change_tara_status", (val) => {
      let params = {
        page: 0,
        size: 9999,
        assignee: this.userAccountId,
        status: "TO_DO",
      };
      get_allRiskAnalysisPage(this.get_pid(), params).then((res) => {
        this.unHandleTARA = res.totalElements;
      });
    });
    if (this.get_pid()) {
      getUnhandleTask(this.get_pid()).then((res) => {
        this.unHandleTask = res;
      });
      notComplete({
        projectId: this.get_pid(),
      }).then((res) => {
        this.unHandleDFMEA = res;
      });
      let params = {
        page: 0,
        size: 9999,
        assignee: this.userAccountId,
        status: "TO_DO",
      };
      get_allRiskAnalysisPage(this.get_pid(), params).then((res) => {
        this.unHandleTARA = res.totalElements;
      });
      getCount({
        projectId: this.get_pid(),
      }).then((res) => {
        this.unHandleRequest = res;
      });
    }
    // window.addEventListener('resize', ()=>{
    //   setTimeout(()=>{
    //     this.$refs.slide.style.width=parseFloat(this.$refs.first_item.offsetWidth)-20+"px"
    //     this.$refs.slide.style.height=parseFloat(this.$refs.first_item.offsetHeight)+"px"
    //   },500)
    // })
    // this.slide_init()
    notComplete({
      projectId: this.get_pid(),
    }).then((res) => {
      this.unHandleDFMEA = res;
    });
    getCount({
      projectId: this.get_pid(),
    }).then((res) => {
      this.unHandleRequest = res;
    });
    this.showDFMEAfn();
    this.getShortcuts();
  },
  created() {
    /**获取用户列表 */
    get_tenant_user_list().then((res) => {
      this.userList = res.content;
    });

    // 将状态管理中的思维导图名称置空
    this.$store.commit("SET_MINDMAP_NAME", "");
  },
  methods: {
    // 获取快捷方式
    getShortcuts() {
      const params = {
        projectId: this.get_pid(),
      };
      getShortcuts(params).then((res) => {
        this.shortcutsList = res;
      });
    },
    // 快捷方式相关方法
    saveShortcuts() {
      this.$refs["shortcutsForm"].validate((valid) => {
        if (valid) {
          const regex = /^(https?:\/\/)/; // 定义正则表达式
          const hasHTTP = regex.test(this.shortcutsForm.url);
          if (!hasHTTP) {
            this.shortcutsForm.url = "https://" + this.shortcutsForm.url;
          }
          const params = {
            projectId: this.get_pid(),
            data: this.shortcutsForm,
          };
          addShortcuts(params).then((res) => {
            this.getShortcuts();
            this.addShortcutsdialog = false;
          });
        } else {
          return false;
        }
      });
    },
    deleteShortcuts(item, index) {
      this.$confirm(
        this.$t("chart.topBar.deleteVersionForm.info"),
        this.$t("chart.topBar.deleteVersionForm.title"),
        {
          confirmButtonText: this.$t("btn.deleteBtn"),
          confirmButtonClass: "delete_button_class",
          cancelButtonText: this.$t("btn.cancelBtn"),
          cancelButtonClass: "delete_cancel_class",
          type: "warning",
        }
      )
        .then(() => {
          const params = {
            projectId: this.get_pid(),
            shortcutId: item.shortcutId,
          };
          deleteShortcuts(params).then((res) => {
            this.getShortcuts();
            this.$message({
              type: "success",
              message: this.$t("baseline.message.deleteSuccess"),
            });
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: this.$t("canvas.messages.deleteInfo"),
          });
        });
    },
    addShortcuts() {
      this.shortcutsForm = {
        url: "",
        name: "",
      };
      this.addShortcutsdialog = true;
    },
    //////
    showDFMEAfn() {
      get_component_license().then((res) => {
        this.licenseList = [];
        for (let item of res) {
          if (item.effective) {
            this.licenseList.push(item.feature);
          }
        }
      });
    },
    getheight(h) {
      this.height = h;
    },
    setNoActive(key) {
      this.noActive = "2";
    },
    collpaseAside() {
      this.isCollapse = !this.isCollapse;
    },
    toMenuPage(index) {
      if (index == "/home/setting/file_type/work_flow") {
        this.$router.push("/home/setting/file_type/work_flow");
      }
    },
    handleClose(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    },
    errorHandler() {
      return true;
    },
    handleSelect(key) {
      if (!key) {
        return;
      }
      if (key == "/home/chart") {
        this.$router.push({
          name: "chart",
          // 通过aside点击进入，不需要验证分享权限
          params: { cur_dashboard: "enterOwn" },
        });
      } else if (key.indexOf("release") !== -1) {
        this.$router.push({
          name: key.split("/")[3],
          // 通过aside点击进入，不需要验证分享权限
          params: { cur_id: "enterOwn" },
        });
      } else if (key == "/home/board") {
        this.$router.push({
          name: "board",
          // 通过aside点击进入，不需要验证分享权限
          params: { sprintId: "enterOwn" },
        });
      } else if (key == "/home/gantt") {
        this.$router.push({
          name: "gantt",
          // 通过aside点击进入，不需要验证分享权限
          params: { cur_gant_id: "enterOwn" },
        });
      } else {
        this.$router.push("/" + this.$route.params.projectId + key);
      }
    },
  },
};
</script>

<style scope lang="scss">
.slide_style {
  position: absolute;
  left: 10px; /* no*/
  top: 55.99px; /* no*/
  // width: 317.5px; /* no*/
  height: 55.99px; /* no*/
  opacity: 1;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.26);
  z-index: 999;

  display: none;
}
.safe {
  span {
    position: relative;
    .safe_number {
      position: absolute;
      top: -8px;
      right: -10px;
      color: #fff;
      background-color: #f56c6c;
      border-radius: 10px;
      height: 8px;
      width: 8px;
      display: flex;
      // padding: 0 6px;
      align-items: center;
      justify-content: center;
    }
  }
}
.pingshenmenu {
  span {
    position: relative;
    .number {
      position: absolute;
      top: -8px;
      right: -20px;
      color: #fff;
      background-color: #f56c6c;
      border-radius: 10px;
      height: 16px;
      display: flex;
      font-size: 12px;
      padding: 0 6px;
      align-items: center;
      justify-content: center;
    }
  }
}
.dfmeamenu {
  span {
    position: relative;
    .number {
      position: absolute;
      top: -8px;
      right: -20px;
      color: #fff;
      background-color: #f56c6c;
      border-radius: 10px;
      height: 16px;
      display: flex;
      font-size: 12px;
      padding: 0 6px;
      align-items: center;
      justify-content: center;
    }
  }
}
.taramenu {
  span {
    position: relative;
    .number {
      position: absolute;
      top: -8px;
      right: -20px;
      background-color: rgb(245, 108, 108);
      border-radius: 10px;
      height: 16px;
      display: flex;
      font-size: 12px;
      padding: 0 6px;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
<style lang="scss">
.el-menu-vertical-demo {
  overflow-y: scroll;
  height: 88vh;
  //edge 浏览器隐藏滚动条
  scrollbar-width: none;
  .iconfont {
    font-size: 18px;
    margin: 0 12px 0 5px;
    color: white;
  }
  .el-submenu__title i {
    color: white;
  }
  text-align: left !important;
  .el-menu-item.is-active {
    background-color: transparent;
  }

  &:not(.el-menu--collapse) {
    width: 270px;
  }
}
.el-menu--popup {
  svg {
    margin-right: 12px !important;
  }
}
.left-menu {
  .el-menu-item {
    height: 52px;
    line-height: 52px;
  }
  .el-submenu__title {
    height: 52px;
    line-height: 52px;
  }
}
.el-submenu .el-menu {
  background-color: transparent;
}
// 补充的代码

.el-submenu__title:hover {
  background-color: transparent;
}

.el-menu--vertical .el-menu--popup .el-menu-item {
  background-color: rgba(1, 76, 170, 1);
}
.el-menu--vertical .el-menu--popup .el-menu-item i {
  color: #fff;
  margin-right: 20px;
}
.el-menu--vertical .el-submenu__title i {
  color: #fff;
  margin-right: 20px;
}

.el-menu--vertical .el-menu--popup .el-menu-item:hover {
  background-color: rgba(1, 76, 170, 0.8) !important;
}

.el-menu--vertical .el-submenu .el-submenu__title {
  background-color: rgba(1, 76, 170, 1);
}

.el-menu--vertical .el-submenu .el-submenu__title:hover {
  background-color: rgba(1, 76, 170, 0.8) !important;
}

.el-menu--vertical .el-menu--popup .el-menu-item {
  background-color: rgba(1, 76, 170, 1);
}

.el-menu--vertical .el-menu--popup .el-menu-item:hover {
  background-color: rgba(1, 76, 170, 0.8) !important;
}

.el-menu--vertical .el-submenu .el-submenu__title {
  background-color: rgba(1, 76, 170, 1);
}

.el-menu--vertical .el-submenu .el-submenu__title:hover {
  background-color: rgba(1, 76, 170, 0.8) !important;
}
</style>
<style lang="scss" scoped>
/*.box2{
  &:hover{
    background-color: rgb(38, 80, 114);
  }
}*/
.test-collapse {
  overflow-y: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  &:not(.el-menu--collapse) {
    width: 270px;
  }
  .el-menu-item.is-active {
    background-color: transparent !important;
  }

  .el-menu-item:hover {
    background-color: rgb(38, 80, 114);
    //  修改aside最下面的
  }
}
.collapse-box {
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: 12px;
  font-size: 16px;
  color: #f0f2f5;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 30px;
  // display:none;
}
.home {
  position: relative;
  .default-button {
    width: 100%;
    height: 45px;
    background: #34afe0;
    color: white;
    border: 0;
    transition-duration: 0.3s;
  }

  .default-button:hover {
    background: #0074b7;
  }

  .el-aside {
    background: linear-gradient(
      180deg,
      rgba(1, 76, 170, 1) 31.67%,
      rgba(3, 122, 188, 1) 100%
    );
  }

  .el-main {
    background-color: #f0f2f5;
    color: #333;
    overflow: hidden;
  }

  .el-menu {
    border: none;
    // ::v-deep .el-submenu__title {
    //  padding-left: 0.1042rem !important;

    // }
    background-color: transparent !important;
    overflow-x: hidden;
  }
  .el-menu-vertical-demo .el-menu-item.is-active {
    position: relative;
  }
  .el-menu-vertical-demo .el-menu-item.is-active::before {
    position: absolute;
    content: "";
    top: 5%;
    right: 5%;
    bottom: 5%;
    left: 5%;
    z-index: 1;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.26);
  }
  .el-menu-item {
    color: #f0f2f5;
    text-align: left;
    background-color: transparent;
  }
  el-submenu {
    text-align: left;
    background-color: transparent;
  }

  .el-dropdown-menu {
    padding: 15px;
    text-align: left;
  }
  .el-header {
    padding: 0px; /* PERF 建议全局修改默认样式 */
  }

  .head-content {
    display: flex;
  }

  .el-container {
    height: 95vh;
  }
  .el-dropdown-link {
    cursor: pointer;
    color: #409eff;
  }
  // .el-form-item {
  //   margin-bottom: 15px;
  // }
  .dialog-title {
    text-align: left;
    font-size: 18px;
    font-weight: 700;
    overflow: hidden;
  }
  .element-item-group__title {
    height: 0px !important;
  }
  .settingClass.el-submenu {
    height: 45px;
  }
  .typeClass.el-submenu {
    height: 45px;
  }
}
.member-input-box ::v-deep.el-scrollbar__wrap {
  overflow-x: hidden;
}
</style>
<style scoped>
/*滚动条bai整体*/
::-webkit-scrollbar {
  opacity: 0;
  width: 0px; /*滚动条宽度*/
}
/*滚动条按钮*/
::-webkit-scrollbar-button {
  background: rgba(255, 255, 255, 0.26);
  background: transparent;
}
::-webkit-scrollbar-track {
  /* background-color: rgba(48, 100, 143, 1); 滑道全部 */
  background-color: transparent;
}
::-webkit-scrollbar-track-piece {
  /* background-color: rgba(48, 100, 143, 1); 滑道 */
  background-color: transparent;
  -webkit-border-radius: 4px; /*滑道圆角宽度*/
}
::-webkit-scrollbar-thumb {
  /* background-color: rgba(48, 100, 143, 1); */

  /* border: solid 1px rgba(48, 100, 143, 1); 滑动条边框 */
  border-radius: 4px; /*滑动条圆角宽度*/
}
/*横竖滚动条交角*/
::-webkit-scrollbar-corner {
  /* background-color: rgba(48, 100, 143, 1); */
  background-color: transparent;
}
/*横竖滚动条交角图案*/
::-webkit-resizer {
  /*background-image: url(/public/img/resizer-inactive.png);*/
  background-repeat: no-repeat;
  background-position: bottom right;
}
/*鼠标滑过滑动条*/
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(48, 100, 143, 1);
  /* background-color: transparent!important; */
}
</style>
